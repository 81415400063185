.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.page {
  position: absolute;
  top: min(20vmin, 150px);
  left: 0;
  right: 0;
  min-height: calc(100vh - min(20vmin, 150px));
}

.footer {
  position: absolute;
  bottom:0;
  height: 75px;
  background-color: #2e5670;
  color: #fff;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fade-appear,
.fade-enter {
  opacity: 0;
  z-index: 1;
}
.fade-appear-active,
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 150ms linear 150ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 150ms linear;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.title {
  height: 20vmin;
  max-height: 150px;
}

.font-dark {
  color: #2e5670;
}

.border-dark {
  border-color: #2e5670;
}

.min-w-100 {
  min-width: 100px;
}

.App-link {
  color: #61dafb;
}

.active .link-text {
  border-bottom: 2px solid #799eb4;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.about-content {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.about-content .image {
  object-fit: contain;
  width: 45%;
}

.about-content .description {
  width: 50%;
}

@media (max-width: 900px) {
  .about-content {
    flex-direction: column;
    align-items: center;
  }

  .about-content .image {
    width: 75%;
    margin-bottom: 1rem;
  }

  .about-content .description {
    width: 100%;
  }
}

.divider {
  height: 2px;
  background-color: #2e5670;

  width: 90%;
  margin: auto;
  margin-bottom: 1rem;
}

.spacer {
  height: 2px;

  width: 90%;
  margin: auto;
  margin-bottom: 1rem;
}
