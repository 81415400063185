/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css?family=Montserrat:regular,bold,semibold,italic&subset=latin,latin-ext");

* {
  font-family: "Montserrat", sans-serif;
}
